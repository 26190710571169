import './style.css'
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { PlaneBufferGeometry } from "three";

// add this Joe
const resetHands = (hands) => {
    {
        // hands are at 10 and 2 and 36 seconds, so we're resetting to 0 position
        var sReset = (36.0/60.0) * 360.0 * (Math.PI/180.0);
        var mReset = (9.0/60.0) * 360.0 * (Math.PI/180.0);
        var hReset = (10.0/12.0) * 360.0 * (Math.PI/180.0);
        hands.second.rotation.y  = sReset;
        hands.minute.rotation.y  = mReset;
        hands.hour.rotation.y  = hReset;
    }
}

const findObjectWithName = (obj,name) => {
    if(obj == null)
    {
        return null;
    }

    let res = null;
    if(("name" in obj))
    {
        if(obj.name.match(name))
        {
            return obj;
        }
    }

    if(!("children" in obj))
    {
        return;
    }

    obj.children.forEach(function(child,i)
    {
        var childResult = findObjectWithName(child,name);
        if(childResult != null)
        {
            res = childResult;
        }
    });

    return res;
}

const findHands = (rootObj) => {
    //TODO: if we standardize names we can do rootObj.getObjectWithName which is likely more efficient
    let result = {
        'hour' : findObjectWithName(rootObj,"hours_hand"),
        'minute' : findObjectWithName(rootObj,"minutes_hand"),
        'second' : findObjectWithName(rootObj,"seconds_hand")
    };

    return result;
}
// - JM


const updateAllMaterials = () => {
    scene.traverse(child => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            child.material.envMap = envMap;
            child.material.envMapIntensity = debug.envMapIntensity;
            child.castShadow = true;
            child.receiveShadow = true;
        }
    })
}

// update normal map of case back engraving
const updateNormalMap = () => {
    scene.traverse(child => {
        if (child instanceof THREE.Mesh && child.name === 'back_engraving001') {
            child.material.normalScale.set(3,-3);
        }
    })
}

// place GLB
const placeGLB = (gltf) => {
    gltf.scene.rotation.set(Math.PI / 2, 0, 0);
    gltf.scene.scale.set(1, 1, 1);
    gltf.scene.position.y = 0;
}

// debug
const debug = {};
debug.envMapIntensity = 2.5;

// canvas
const canvas = document.querySelector('canvas.webgl');

// scene
const scene = new THREE.Scene();
scene.background = null; //new THREE.Color();

// lights
const ambientLight = new THREE.AmbientLight('#ffffff', 2);
scene.add(ambientLight);

const pointLight01 = new THREE.PointLight('#ffffff', 0.25);
pointLight01.position.set(-0.5, 0.3, 0.4);
// pointLight01.castShadow = true;
// pointLight01.shadow.mapSize.se(512, 512);
// pointLight01.shadow.radius = 5;
// pointLight01.shadow.normalBias = 0.25;
// pointLight01.shadow.camera.near = 0.1;
// pointLight01.shadow.camera.far = 5;
scene.add(pointLight01);

const pointLight02 = new THREE.PointLight('#ffffff', 0.25);
pointLight02.position.set(-0.5, -0.5, 0.45);
scene.add(pointLight02);

const pointLight03 = new THREE.PointLight('#ffffff', 0.25);
pointLight03.position.set(0.5, 0.2, 0.15);
scene.add(pointLight03);

// textures
const textureLoader = new THREE.TextureLoader();
const shadowMask1k = textureLoader.load('static/textures/shadowMask1k.png');

// this is for applying a background image...
// const bgColor = textureLoader.load('textures/bg_pattern.png');
// bgColor.wrapS = THREE.RepeatWrapping;
// bgColor.wrapT = THREE.RepeatWrapping;
// bgColor.repeat.set(2, 1);
// bgColor.encoding = THREE.sRGBEncoding;
// scene.background = bgColor;

const cubeTextureLoader = new THREE.CubeTextureLoader();
const envMap = cubeTextureLoader.load([
    '/static/textures/environmentMaps/4/px.png',
    '/static/textures/environmentMaps/4/nx.png',
    '/static/textures/environmentMaps/4/py.png',
    '/static/textures/environmentMaps/4/ny.png',
    '/static/textures/environmentMaps/4/pz.png',
    '/static/textures/environmentMaps/4/nz.png'
]);
envMap.encoding = THREE.sRGBEncoding;
// scene.background = envMap;

// models
// watch group
const watchGrp = new THREE.Group();
scene.add(watchGrp);

// floor
const floor = new THREE.Mesh(
    new PlaneBufferGeometry(1, 1, 1),
    new THREE.MeshStandardMaterial({
        color: 0x000000,
        transparent: true,
        // wireframe: true,
        alphaMap: shadowMask1k
    })
)
floor.rotation.x = -Math.PI / 2;
floor.position.set(0, -0.4, 0);
// floor.receiveShadow = true;
scene.add(floor);

// floor watch grp
const floorWatchGrp = new THREE.Group();
floorWatchGrp.add(watchGrp, floor);
scene.add(floorWatchGrp);

// load gltfs
const gltfLoader = new GLTFLoader();


// case
let caseAlbedo = new THREE.Texture();
let hands;
gltfLoader.load('/static/models/watch/gltf/case_stainless_steel.glb', (gltf) => {
    placeGLB(gltf);

    hands = findHands(gltf.scene);
    resetHands(hands);

    // save the case albedo map
    gltf.scene.traverse(child => {
        if (child instanceof THREE.Mesh && child.name === 'case_bottom001') {
            caseAlbedo = child.material.map;
            return;
        }
    });
    watchGrp.add(gltf.scene);
    updateAllMaterials();
    
    gltfLoader.load('/static/models/watch/gltf/fun_band_banana.glb', (gltf) => {
        gltf.scene.traverse(child => {
            if (child instanceof THREE.Mesh && child.name === 'buckle001' || child instanceof THREE.Mesh && child.name === 'strap_holder001') {
                const buckleMat = child.material.clone();
                buckleMat.map = caseAlbedo;
                child.material = buckleMat;
            }
        })

        placeGLB(gltf);
        watchGrp.add(gltf.scene);
        updateAllMaterials();
    });
})


// back engraving
gltfLoader.load('/static/models/watch/gltf/back_engraving.glb', (gltf) => {
    placeGLB(gltf);
    watchGrp.add(gltf.scene);
    updateAllMaterials();
    updateNormalMap();
})

// dial design
gltfLoader.load('/static/models/watch/gltf/dial_design_01.glb', (gltf) => {
    placeGLB(gltf);
    watchGrp.add(gltf.scene);
    updateAllMaterials();
})

// face design
gltfLoader.load('/static/models/watch/gltf/face_design_01.glb', (gltf) => {
    placeGLB(gltf);
    watchGrp.add(gltf.scene);
    updateAllMaterials();
})





// rotate the floor and watch together using the group
floorWatchGrp.rotation.y = 0.3;
// line up the watch with the world axis, so it rotates along that
watchGrp.position.z = 0.315;

// sizes
const sizes = {
    width: canvas.clientWidth, //window.innerWidth,
    height: canvas.clientHeight //window.innerHeight
}

window.addEventListener('resize', () => {
    // update sizes
    if(canvas.clientWidth > canvas.clientHeight){
        canvas.style.width = canvas.clientHeight
    }
    else{
        canvas.style.height = canvas.clientWidth
    }
    sizes.width = canvas.clientWidth; //window.innerWidth;
    sizes.height = canvas.clientHeight; //window.innerHeight;

    // update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix();

    // update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

// base camera
const camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height, 0.1, 1000)
camera.position.set(0, -0.125, 1.35)
scene.add(camera);

// controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
controls.enablePan = false;
controls.enableZoom = false;

// limit the polar angle of orbit controls
controls.maxPolarAngle = 1.82;

// renderer
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.physicallyCorrectLights = true;
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.toneMappingExposure = 3;
renderer.shadowMapEnabled = true;
renderer.shadowMap.type = THREE.PCFShadowMap;

renderer.setClearColor( 0xffffff, 0);

// animate
const clock = new THREE.Clock();

// background color
//scene.background = new THREE.Color( 0xff0000 );

const animate = () => {
    const elapsedTime = clock.getElapsedTime();
    // watchGrp.rotation.x = -Math.PI / 17.5 + Math.cos(elapsedTime / 4) / 8;
    // floorWatchGrp.rotation.y = Math.sin(elapsedTime / 4) / 8;
    watchGrp.rotation.z = (1 + Math.sin(elapsedTime / 1.25)) / 100;
    watchGrp.position.y = (1 + Math.sin(elapsedTime / 1.25)) / 40;

    // animate background
    //scene.background.copy(color1).lerp(color2, 0.5 * (Math.sin(controls.getAzimuthalAngle()) + 1));

    // update the shadow
    floor.material.opacity = (1 - watchGrp.position.y) * 0.70;
    floor.scale.x = (1 - watchGrp.position.y) * 0.85;
    floor.scale.y = (1 - watchGrp.position.y) * 0.85;


    // add this Joe
    if (hands != null )
    {
        var d = new Date();

        var s = d.getSeconds();
        var m = d.getMinutes();
        var h = d.getHours() + m / 60.0;

        var sReset = (36.0/60.0) * 360.0 * (Math.PI/180.0);
        var mReset = (9.0/60.0) * 360.0 * (Math.PI/180.0);
        var hReset = (10.0/12.0) * 360.0 * (Math.PI/180.0);

        //percent * 360 => to radians, we use negative
        var sRads = (s/60.0) * 360.0 * (Math.PI/180.0);
        //flip b/c our rotation is the opposite of a clock
        hands.second.rotation.y = sReset + -sRads;

        var mRads = (m/60.0) * 360.0 * (Math.PI/180.0);
        hands.minute.rotation.y = mReset + -mRads;

        var hRads = ((h%12)/12.0) * 360.0 * (Math.PI/180.0);
        hands.hour.rotation.y = hReset + -hRads;
    }
    // - JM


    controls.update();
    renderer.render(scene, camera);
    window.requestAnimationFrame(animate);
}

animate();

